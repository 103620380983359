.dragging-element {
  position: fixed;
  opacity: 0.7;
  border: 2px dotted darken($table-border-color, 10%);
  font-size: 12px;
  cursor: move;
  z-index: 20;
  @include no-select;
  @include pretty-textoverflow;
}
