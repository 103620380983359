.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.small-text {
  font-size: 85%;
}

.smaller-text {
  font-size: 85%;
}

.light-text {
  color: $lightgrey;
  opacity: 0.8;
  font-size: 0.8em;
}

.lighter-small-text {
  color: $lightgrey;
  opacity: 0.3;
  font-size: 0.8em;
}

.white-text {
  color: white;
}

.black-text {
  color: $black !important;
}

.red-text {
  color: $state-danger-text !important;
}

.bright-red-text {
  color: $brightRed !important;
}

.dark-red-text {
  color: $darkRed;
}

.bright-green-text {
  color: $brightGreen !important;
}

.green-text {
  color: $greenSuccess;
}

.dark-green-text {
  color: #5a7755;
}

.bright-red-text {
  color: $brightRed !important;
}

.border-bright-red-text {
  border: 1px solid $brightRed !important;
}

.yellow-text {
  color: $yellowColor !important;
}

.default-blue-text {
  color: $defaultBlue !important;
}

.blue-text {
  color: $blueColor;
}

.poop-text {
  color: $poop;
}

.pretty-text-50 {
  @extend .pretty-text;
  max-width: 50px;
}

.pretty-text-70 {
  @extend .pretty-text;
  max-width: 70px;
}

.pretty-text-80 {
  @extend .pretty-text;
  max-width: 80px;
}

.pretty-text-85 {
  @extend .pretty-text;
  max-width: 85px;
}

.pretty-text-100 {
  @extend .pretty-text;
  max-width: 100px;
}

.pretty-text-125 {
  @extend .pretty-text;
  max-width: 125px;
}

// 28 chars
.pretty-text-150 {
  @extend .pretty-text;
  max-width: 150px;
}

// 30 chars of font-12
.pretty-text-175 {
  @extend .pretty-text;
  max-width: 175px;
}

// 32 chars of font-12
.pretty-text-180 {
  @extend .pretty-text;
  max-width: 180px;
}

.pretty-text-200 {
  @extend .pretty-text;
  max-width: 200px;
}

.pretty-text-250 {
  @extend .pretty-text;
  max-width: 250px;
}

.pretty-text-300 {
  @extend .pretty-text;
  max-width: 300px;
}
