.popover-content {
  padding: 10px;
}

.tooltip-popover {
  @extend .tooltip-inner;

  &.right > .arrow:after {
    border-right-color: $poop;
  }
  &.left > .arrow:after {
    border-left-color: $poop;
  }
  &.top > .arrow:after {
    border-top-color: $poop;
  }
  &.bottom > .arrow:after {
    border-bottom-color: $poop;
  }
}

.popover-75 {
  max-width: 750px;
}
.popover-50 {
  max-width: 500px;
}

.no-max-width {
  max-width: inherit;
}

.initial-width {
  max-width: initial;
}
