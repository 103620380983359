#removeable-column-popover {
  display: block;
  min-width: 160px;
  .popover-content {
    max-height: 600px;
    // overflow: auto;
  }
  .fa-long-arrow-up {
    width: 10px;
  }
  .column-body {
    max-height: 575px;
    overflow-y: auto;
    ul {
      height: 100%;
    }
  }
  .fa-long-arrow-up {
    position: absolute;
    margin-top: 2px;
    & + label {
      padding-left: 15px;
    }
  }
  .regular-popover-inner {
    .column-body {
      max-height: 545px;
    }
  }
}
column-management-comp {
  display: inline-block;
}
