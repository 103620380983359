#login {
  #tkg-banner {
    width: 100%;
    h1.app-name {
      position: absolute;
      right: 25px;
      top: 0;
      font-size: 30px;
    }
  }
}

.nms-filter {
  .form-inline {

    .alert-success {
      padding: 2.5px 10px !important;
      margin-left: 15px;
    }

  }
}

#tkg-banner {
  display: flex;
  align-items: center;
  h1 {
    line-height: 50px !important;
    font-size: 35px;
  }
}

#tkg-header {
  $bot: 5px;
  form {
    left: 105px;
    padding-bottom: $bot;
  }
  form table td{
    padding-bottom: 0 !important;
  }
  #sticky-header-bottom {
    bottom: $bot;
    .sm-hourglass {
      height: 12px;
    }
  }
  // .hint-list {
  //   margin-left: 5px;
  // }
}

#tkg-header, .modal-header {
  button.btn:not(.has-error) {
    border-color: #2d5636 !important;
  }
  .dropdown-menu {
    button.btn.btn-poop {
      border-color: white;
    }
  }
}

#sticky-header {
  & > * {
    margin-left: 5px;
    &:not(:last-child):after {
      content: '|';
      width: 5px;
      margin-left: 5px;
      font-weight: normal;
    }
  }
}

.sticky-bottom-tooltip.tooltip {
  position: fixed;
  top: 25px !important;
  .tooltip-arrow {
    display: none;
  }
}

.tooltip-error.tooltip {
  .tooltip-inner {
    color: $redColor;
  }
}

.tmpl-setting {
  i.right-spacing-xs {
    margin-right: 15px !important;
  }
}

.password-dropdown {
  margin-left: -62px !important;
}
.modal-password {
  .modal-dialog {
    right: 5px;
    position: absolute;
    width: 260px;
  }
}

tkg-header .fa-camera {
  position: absolute;
  right: 152px;
  bottom: 8px;
}
