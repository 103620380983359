.modal-trading-data {
  .modal-header {
    button {
      height: 23px;
    }
  }
  .modal-body {
    padding-bottom: 25px;
  }
}
