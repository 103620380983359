th {
  background-color: #f8f8f8;
}

.modal {
  th {
    background-color: inherit;
  }
}

.popover {
  max-width: inherit;
  .badge {
    background-color: $green;
    margin-top: -3px;
    display: inline-block;
    margin-right: 5px;
  }
}

.popover-comment {
  height: 85;
  width: 300px;
  textarea {
    height: 63px;
  }
}

.with-add-on {
  .fa-ellipsis-h {
    right: 0;
    width: 21px;
    color: grey;
    &:before {
      margin-top: 1px;
      display: inline-block;
      width: 21px;
      text-align: center;
    }
  }
}

$logoSize: 40px;
.tkg-logo {
  background: url('../assets/images/TKG_highRes_50.jpg');
  background-size: $logoSize $logoSize;
  width: $logoSize;
  height: $logoSize;
  display: inline-block;
}

.small-label {
  font-size: 10px !important;
  vertical-align: middle;
}

#removeable-column-popover {
  $popoverContent: 385px;
  .popover-content {
    padding-bottom: 0;
    height: $popoverContent;
  }
  .column-body {
    height: $popoverContent - 90px;
  }
  .column-footer {
    margin-top: 10px;
  }
}

#sticky-bottom-2 {
  position: absolute;
  right: 45px;
  bottom: 5px;
}

.pull-right-center > button{
  margin-top: -6px;
}
