$inactiveColor: #f8f8f8;
$inactiveBorderColor: #eeeeee;
$activeColor: #fafdfa;
$activeBorderColor: #d9e7d9;
$wrapperHeight: $itemHeight * 5 + 10px;

.list-group-wrapper {
  border: 1px solid $inactiveBorderColor;
  background-color: $inactiveColor;
  border-radius: 2px;
  padding: 5px 0;
  // padding: 5px 0;
  height: $categoryHeight - 30;
  // overflow-y: auto;
  clear: both;
  // min-height: $wrapperHeight;
  &.half {
    height: ($categoryHeight - 30) / 2 - 17;
  }
  &.third {
    height: ($categoryHeight - 30) / 2 - 22;
  }
  &.expand {
    background-color: $activeColor;
    border-color: $activeBorderColor;
    .list-group-item {
      background-color: $activeColor;
      &.pink-bg {
        background-color: $lightPink;
      }
    }
  }

  & ul {
    height: 100%;
    overflow-y: auto;
  }

  uib-accordion {
    ul.list-group {
      overflow-y: hidden;
    }
    .panel {
      border: none;
      box-shadow: none;
      border-radius: 0;
      a {
        color: $black;
        font-weight: normal;
        text-decoration: none;
      }
      .list-group a {
        // text-align: right;
        padding-left: 15px;
      }
    }
    .panel-heading {
      height: 24px;
      & * {
        font-size: 14px;
      }
      a {
        color: $black;
        font-weight: normal;
      }
    }
    .panel-body {
      border: none !important;
      border-bottom: $table-border;
    }
    .panel-title {
      .fa {
        margin: 0;
      }
    }
    .panel-group {
      margin: 0;
      .panel + .panel {
        margin-top: 0;
      }
    }
    [role="tab"] {
      @extend .list-group-item;
      height: 22px;
    }
  }
  table:not(.uib-timepicker):not(.mc-table) {
    td {
      font-size: 12px;
      padding: 1px 5px !important;
      select {
        max-width: 98px;
      }
    }
  }

}

.nms-filter {
  // $inactiveColor: #f8f8f8;
  // $inactiveBorderColor: #eeeeee;
  // $activeColor: #fafdfa;
  // $activeBorderColor: #d9e7d9;
  // $wrapperHeight: $itemHeight * 5 + 10px;

  .split, .split-3 {
    .filter-category:not(:first-child) {
      margin-top: 5px;
      margin-bottom: 5px !important;
    }
    &.extra-line {
      .list-group-wrapper {
        height: ($categoryHeight - 30 * 2) /2 - 10;
      }
    }
  }
  .split {
    .list-group-wrapper {
      height: ($categoryHeight - 30 * 2) /2;
    }
  }

  .split-3 {
    .list-group-wrapper {
      height: ($categoryHeight - 44 * 2) /3;
    }
  }

  .list-group-summary-wrapper {
    @extend .list-group-wrapper;
    height: inherit;
    max-height: $categoryHeight/2 - 30;
  }

  $max: 5;
  @for $i from  1 through $max {
    .compressed-#{$i}.list-group-wrapper {
      height: $wrapperHeight + (($max - $i) * $itemHeight) - 4px;
      &.half {
        height: (($max - $i) * $itemHeight) - 4 * 3;
      }
      &.third {
        height: (($max - $i) * $itemHeight) - 4 * 7;
      }
    }
    .expand-#{$i}.list-group-wrapper {
      height: $itemHeight * $i + 14px;
      // &.half {
      //   height: ($itemHeight * $i + 14) / 2;
      // }
      @if $i != 5 {
        overflow: hidden;
      }
    }
  }

  .list-group {
    margin: 0;
    color: $black;
  }
  .list-group-item {
    background-color: $inactiveColor;
    padding: 0;
    border: none;


    & > * {
      display: block;
      padding: 3px 10px;
      line-height: initial;
      // padding-left: 10px;
      // line-height: 14px;
    }
    // label > span:first-child {
    //   float: left;
    // }
    .fa:not(.fa-asterisk) {
      float: right;
    }
    .fa-asterisk {
      color: lighten($darkGrey, 20%);
      font-size: 8px;
      vertical-align: middle;
      margin-top: -2px;
    }
    .fa-check {
      margin-top: 1px;
      float: left;
    }
    .fa-user {
      width: 14px;
      text-align: center;
    }
  }

  #tkg-header .list-group-item, .dropdown-menu .list-group-item {
    width: 100%;
    display: inline-block;
    label {
      width: 100%;
      float: none;
      color: $black;
      font-size: 14px;
      & > *:not(.label) {
        font-size: 14px;
      }
      & > .label {
        font-size: 75%;
      }
    }
  }

  .list-group-item table {
    .label {
      margin-right: 24px;
    }
  }

  .dropdown-menu {
    .list-group-item {
      background-color: inherit;
    }
    .list-group-item > * {
      padding: 3px 0;
      padding-left: 10px;
    }
  }
}

.sticky-input {
  position: sticky;
  top: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  max-height: none;
  height: 32px !important;
  background-color: white;
  & > input {
    width: 100% !important;
  }
}
