// .legend-wrapper {
//   // @extend .vert-spacing;
//   margin-left: auto;
//   margin-right: auto;
//   width: 50%;
//   border: 1px solid $originalPoop;
//   background-color: $lighterPoop;
//   padding: 5px 10px;
//   line-height: 12px;
// }
// .chart-legend {
//   @extend .pretty-text;
//   width: 50%;
//   &:nth-child(even) {
//     clear: right;
//     // width: inherit;
//     // max-width: 50%;
//   }
// }

#legendCanvas, #legendSVG {
  margin-left: auto;
  margin-right: auto;
  display: block;
  margin-bottom: 15px;
  tree {
    display: block;
    line-height: 0;
  }
  .fa {
    color: $green;
    font-size: 13px;
  }
}


#highchart-container {
  padding: 10px 0;
  font-size: 12px;
  background: white;
  z-index: 2;
  position: absolute;
  width: 100%;
  top: 0;
  .dropdown-menu {
    font-size: 12px;
    min-width: inherit;
  }
}
