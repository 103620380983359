#case-detail {
  .table-wrapper {
    max-height: 200px;
    // height: 200px;
    overflow-y: auto;
  }
  table.selected-case-table {
    th {
      border-top: none;
    }
    width: 100%;
  }

  .fa-paperclip {
    margin: 0;
    position: relative;
    &.active {

    }
  }
  .with-add-on > {
    .fa-paperclip {
      position: absolute;
      top: 3px;
      right: 20px;
    }
  }
  .with-add-on input[type="text"] {
    padding-right: 40px !important;
  }
}
.status-btn {
  max-width: 215px;
  .fa-chevron-down, .fa-caret-down {
    float: right !important;
  }
}

body.uib-dropdown-open[route-ctrl="exceptionCtrl"] {
  overflow-y: hidden;
}

.reason-dropdown {
  margin-top: -100px !important;
  margin-left: 71px !important;
  height: 200px;
  overflow-y: auto;
  z-index: $modalZIndex + 1;
}

// header
#nms-exception {
  @extend .nms-exec-filter;
  .route-venue-input {
    left: 0;
  }
  #tkg-header {
    .input-group-addon {
      // top: 3px;
      height: 17px;
    }
    #tkg-banner {
      padding-top: 6px;
    }
    form {
      td:not(.with-add-on) > * {
        width: 100%;
      }
      td > * {
        &[type="text"] {
          height: 19px;
          border-width: 0px;
        }
      }
      td.has-error > * {
        &[type="text"] {
          border-width: 1px
        }
      }
    }
    input.form-control {
      // width: 100px;
      margin: 0;
    }
    button {
      .fa-chevron-down{
        float: right;
        margin-top: 0px;
      }
      .fa-caret-down {
        float: right;
      }
      .badge {
        margin-top: 0;
        margin-left: 5px;
        margin-right: 0;
        float: none;
      }
    }
    .datepicker-input {
      width: 80px;
    }
  }

  .stock-list-dropdown {
    width: 302px !important;
    .category {
      margin-top: 0;
      margin-right: 0;
    }
    input {
      margin: 0 !important;
    }
  }
  .route-venue-dropdown {
    @extend .stock-list-dropdown;
    // width: 250px !important;
    width: 400px !important;
    // width: auto !important;
    // min-width: 250px;
    & > .category {
      // min-width: 230px;
      width: 100%
    }
  }
  .mc-list-dropdown {
    @extend .stock-list-dropdown;
    width: 343px !important;
    .market-center {
      width: 320px;
      .pretty-text-250 {
        max-width: 200px;
      }
    }
    table tr {
      font-size: 14px;
      background-color: transparent;
      td, th {
        font-size: 14px;
        span:not(.label) {
          font-size: 14px;
        }
        span.label {
          @extend .small;
          width: initial !important;
          margin: 0;
          font-size: 75%;
        }
        &:nth-child(4) {
          width: 50px !important;
          max-width: 50px !important;
        }
      }
    }
  }
}

#modal-score-body {
  table {
    width: 100%;
  }
  #score-table {
    tr td:first-child {
      width: 160px;
      text-align: right;
    }
  }
}

.modal-case-detail {
  @include modalSize {
    width: 750px;
  }
}

.full-case-info-table {
  // tr:nth-child(even) {
    .td-label {
      background-color: $lighterPoop;
    }
    td:not(.td-label) {
      text-align: right;
    }
  //   td:not(.td-label) {
  //     background-color: $hoverGreen;
  //   }
  // }
  tr {
    td:not(:first-child) {
      &:before {
        content: '';
        padding-left: 5px;
      }
    }
  }
  &.non-modal {
    tr:first-child {
      td {
        border-top: 0;
      }
    }
  }
}

.trade-chart {
  height: 100%;
  .highchart-wrapper {
    height: 50%;
    width: 100%
  }
  .pag-wrapper {
    background: $btnGreen;
    color: $white;
  }
  .table-wrapper {
    height: 50%;
    width: 100%;
    .scrollable-div {
      border-top: $table-border;
      .s-head {
        width: 100%;
      }
      .s-th, .s-head {
        background-color: #f8f8f8;
      }
    }
  }
}

.modal {
  .trade-chart {
    height: 500px;
  }
  .highchart-wrapper {
    color: black;
  }
  .pag-wrapper {
    background: transparent;
    .tkg-pagination {
      margin-left: 0
    }
  }
}

.dropdown-menu.hint-list .reasonAutocompleteFilter + ul.dropdown-menu {
  border: 1px solid $dropdown-border;
  position: absolute;
  @include box-shadow(0 6px 12px rgba(0,0,0,.175));
}
