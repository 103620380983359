#password-meter {
  width: 100%;
  position: relative;
  display: block;
  .pw-level {
    background: grey;
    display: inline-block;
    width: 24%;
    height: 5px;
    float: left;
    &.filled {
      &.level-1 {
        background: $redColor;
      }
      &.level-2 {
        background: $yellowColor;
      }
      &.level-3 {
        background: lightgreen;
      }
      &.level-4 {
        background: lighten(green, 10%);
      }
    }
    &:after {
      content: ' ';
      width: 1%;
      display: inline-block;
      // float: left;
    }
    &:not(:first-child) {
      margin-left: 1%;
    }
  }
  .pw-text {
    clear: both;
    text-align: center;
    width: 100%;
  }
}
