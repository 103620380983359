#unauthroized #primary-body {
  .panel {
    // width: 50%;
    width: 530px;
    max-width: initial;

    .panel-heading *:not(strong) {
      text-align: center;
      font-size: initial;
    }
  }
}
