#check {
  .sm-spinner {
    position: absolute;
    margin-top: 2px;
    display: inline-block;
  }
  .srv-name {
    position: absolute;
    display: inline-block;
    left: 80px;
  }
  .srv-row {
    height: 20px;

    .btn-action {
      position: absolute;
      left: 45px;
    }
  }
}
