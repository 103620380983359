.uib-timepicker {
  td {
    input {
      &:focus {
        border-color: $poop;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px $poop;
      }
      border: {
        left: 0;
        right: 0;
        radius: 0;
        color: $poop;

      }
      background: $inactiveColor;
    }
    .glyphicon {
      color: $originalPoop;
    }
  }
}

.uib-datepicker {
  thead > tr:nth-child(2) {
    opacity: 0.8;
  }
}

.datepicker-input.minimum, #nms-606 td .datepicker-input.minimum {
  width: 80px !important;
}
