.fa, .fa[role="button"] {
  cursor: pointer;
}

.fa.disabled {
  cursor: no-drop;
}
.fa-toggle-on, .fa-toggle-off {
  color: $btnGreen;
}

.fa-camera {
  font-size: 14px !important;
}

.fa-random {
  margin-top: 3px;
  // color: $blueColor;
}

.fa-info {
  width: 14px;
  text-align: center;
}

.fa-filter {
  z-index: 3 !important;
}

.btn {
  .fa-bell-o {
    float: left;
    margin-right: 8px;
    margin-top: 1px;
  }
}

.dropdown-menu {
  .fa-close.pull-right {
    margin-top: 3px;
  }
}

.fa-circle {
  width: 11px;
  height: 11px;
  border-radius: 11px;
  &:before {
    content: '';
  }
  &.active {
    background-color: $brand-success;
  }
  &.inactive {
    background-color: $brand-danger;
  }
}

.reserve-fa {
  padding-right: 15px !important;
  .fa {
    margin-right: -12px;
  }
}

.dropdown-menu {
  .sm-hourglass {
    position: relative !important;
    height: 13px;
  }
}
