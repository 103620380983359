.table-msg {
  width: 100%;
  display: block;
  height: 25px;
  border-top: none;
  text-align: center;
  font-weight: normal;
  & > * {
    width: 100%;
  }
}

table.scrollable-table.with-msg {
  & > thead {
    height: 50px;
  }
  & > tbody {
    margin-top: 49px;
  }
}
