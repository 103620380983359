#loading-bar-spinner {
  .spinner-icon {
    border: {
      top-color: $poop;
      left-color: $poop;
    }
    width: 35px;
    height: 35px;
    border-width: 5px;
    border-radius: 50px;
  }
}

#loading-bar {
  .bar {
    background-color: $poop;
    height: 3px;
  }
}

.ajax-loader-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: $ajaxLoader;
  background-color: white;
  bottom: 0;
  top: 0;
  display: none;
  overflow: hidden;
}

.ajax-loader {
  margin: auto;
  background: url('/assets/images/ajax-loader.gif');
  background-size: 32px 32px;
  width: 32px;
  height: 32px;
  top: 48%;
  left: 48%;
  position: absolute;
}

.sm-spinner {
  background: url('/assets/images/small_spinner.gif');
  background-size: 14px 14px;
  width: 14px;
  height: 14px;
  opacity: 1;
}

.sm-hourglass {
  background: url('/assets/images/hourglass.gif');
  background-size: 14px 14px;
  width: 14px;
  height: 14px;
}

.loading {
  color: $blueErrorColor;
  .one {
    opacity: 0;
    animation: dot 1.3s infinite;
    animation-delay: 0.0s;
    &:after {
      content: ".";
    }
  }

  .two {
    opacity: 0;
    animation: dot 1.3s infinite;
    animation-delay: 0.2s;
    &:after {
      content: ".";
    }
  }

  .three {
    opacity: 0;
    animation: dot 1.3s infinite;
    animation-delay: 0.3s;
    &:after {
      content: ".";
    }
  }
}

.modal {
  .loading {
    color: white;
  }
}

@keyframes dot {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
