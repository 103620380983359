.builder {
  ul {
    padding: 0;
  }
  .dropdown-menu[uib-dropdown-menu] {
    min-width: 200px;
  }
  .hint-list {
    height: 300px;
    overflow: auto;
    li {
      height: initial;
    }
  }

  label {
    line-height: 18px;
  }

}

.index-builder-modal {
  .modal-dialog {
    width: 1170px;
  }
  .symbol-list {
    height: 150px;
    overflow: auto;
  }
}

.mc-builder-modal {
  .modal-dialog {
    width: 990px;
  }
  .market-center-wrapper {
    margin-right: 0;
  }
}

#builder-block {
  width: 100%;
  height: 300px;
  position: absolute;
  opacity: 0.3;
  background: white;
  z-index: 2;
  left: 0;
}

#btn-clone {
  z-index: 3;
}
