#nms-606 {
  @extend #nms-exception;
  #tkg-banner h1 {
    line-height: 40px !important;
  }

  td [uib-datepicker-popup] {
    width: 100% !important;
    max-width: 128px !important;
  }
}

.attachments-606 {
  li {
    display: flex;
    line-height: 13px;
  }
}

.spacing-child {
  & > *:not(:first-child) {
    margin-left: 10px;
  }
}

.auto-complete-606-comp {
  &:not(:first-child) {
    margin-left: 12px;
  }
  input[type="text"] {
    width: calc(100% - 25px) !important;
    max-width: 100px;
  }
}
