$activeColor: #f8f8f8;

checkbox-list-comp {

  .list-group-wrapper {
    height: auto;
  }
  .list-group {
    margin-bottom: 0;
  }
  .list-group-item {
    padding: 0 5px;
    border: none;
    background: $activeColor;
    label {
      margin: 0;
    }
  }
  .btn .badge {
    margin-top: 0;
    margin-left: 5px;
    margin-right: 0;
    float: none;
    font-size: 12px;
  }
}
