.dropdown-menu {
  color: $black;
  padding: 5px;
  & > li {
    & > a {
      display: inline-block;
      width: 100%;
      padding: 3px 5px;
    }
    & > label {
      width: 100%;
    }
  }
  overflow: auto;
  &.scroll {
    max-height: 300px;
    overflow-y: auto;
  }
  &.normal-width {
    min-width: inherit;
  }
  &.left-side {
    margin-left: -150px;
    &.left-side-200 {
      margin-left: -200px;
    }
  }
  &.input-left {
    & > li > label > input {
      float: left;
      &[type="checkbox"], &[type="radio"] {
        margin-top: 3px;
      }
    }
  }
}

.cusip-dropdown {
  position: initial;
  float: right;
}

.setting-dropdown {
  width: 310px;
}

.dropdown-right-align {
  right: 0 !important;
  left: auto !important;
}

.new-upload-dropdown {
  padding: 5px;
  overflow: auto;
  li {
    a {
      height: 20px;
      i {
        float: left;
        margin-top: 3px;
        font-size: 14px;
      }
      i.fa-check {
        color: $greenSuccess;
      }
    }
  }
}
