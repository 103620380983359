.nms-exec-filter {
  input[type="radio"] {
    height: 14px;
  }
  @extend .nms-filter;
  .input-filter {
    width: 110px;
     & > .filter-category:not(:first-child), & > *:not(:first-child) .filter-category {
      margin-bottom: 5px !important;
    }
    .btn-group {
      margin-bottom: 10px;
    }
    input[type="text"] {
      width: 100%;
    }
  }
  .route-venue-filter {
    min-width: 318px;
  }
  .route-venue-input {
    position: absolute;
    left: 100px;
    width: 180px !important;
    top: 0;
  }
  .date-filter {
    @extend .input-filter;
    width: 130px;
    button.dropdown-toggle {
      width: 100%;
      & > span {
        @extend .pretty-text;
        width: 82px;
        float: left;
      }
      & > .fa {
        float: right;
        margin-left: 0;
      }
    }
    [uib-dropdown] {
      width: 100% !important;
    }
    input[type="text"] {
      width: 100%;
    }
    .list-group-wrapper {
      padding: 8px 8px;
    }
    .uib-timepicker {
      margin: 0;
      .uib-separator {
        color: $black;
        padding: 0 3px !important;
      }
      .glyphicon {
        font-size: 12px;
      }
      input[type="text"] {
        width: 30px;
      }
    }
  }

  .field-list-group-wrapper {
    overflow-y: auto;
    ul {
      height: initial;
      overflow: hidden;
    }
  }
  // .input-filter {
  //   width: 100px;
  // }

  .stock-list {
    width: 280px;
    input[type="text"] {
      width: 120px !important;
    }
  }

  .originator-filter {
    width: 170px;
  }

  .order-filter {
    width: 200px;
  }

  .regex-filter {
    width: 150px;
    // select {
    //   float: left;
    //   margin-right: 5px;
    //   width: 63px;
    //   font-size: 12px;
    // }
    button[uib-dropdown-toggle] {
      float: left;
      border-color: $poop;
      margin-right: 5px;
      width: 65px;
      & > * {
        @extend .pretty-text-50;
      }
    }
    .regex-child {
      display: inline-block;
      &:not(:first-child) {
        margin-top: 10px;
        .filter-category {
          margin-bottom: 5px !important;
        }
      }
      input {
        width: 80px;
        margin: 0;
      }
    }
  }

  .category {
    input[type="radio"] {
      margin-top: 0;
    }
  }

  .show-input {
    input[type="checkbox"] {
      display: inherit;
      margin-top: 1px;
      float: right;
    }
  }

  .field-selection {
    width: 270px;
  }
}

.nms-filter, .nms-audit {
  .full-case-info-table {
    .td-label {
      width: 120px;
      & + td {
        width: 120px;
      }
    }
  }
}

// .overflow-child .regex-child:last-child {
//   position: absolute;
//   bottom: 10px;
//   left: -155px;
// }

.nms-audit {
  .order-wrapper > * {
    min-width: 1550px;
  }
  .advance-order-wrapper > * {
    min-width: 1740px;
  }
  .trans-wrapper > * {
    min-width: 1100px;
  }
  .variable-wrapper > * {
    min-width: 1370px;
  }
  .analysis-wrapper > * {
    min-width: 1620px;
  }
  .published-wrapper > * {
    min-width: 1195px;
  }
  .handling-wrapper > * {
    min-width: 1280px;
  }
  .field-wrapper > * {
    min-width: 1260px;
  }
  .field-selection {
    width: 285px;
  }
}

// .audit-removeable-popover {
//   min-width: 430px !important;
//   .group-column {
//     width: 33%;
//   }
//   .field-column {
//     width: 66%;
//   }
// }
