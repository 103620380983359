th {
  i {
    // float: right;
    position: absolute;
    right: 0;
    margin: 5px;
    margin-right: 0;
    padding: 0 2px;
    z-index: 2;
    // @include transition(opacity, 2s);
  }
  .fa-arrow-up, .fa-arrow-down {
    opacity: 1;
  }
  .fa-filter {
    width: 20px;
    text-align: right;
  }
  // .fa-arrow-up-add, .fa-arrow-down-add {
  //   opacity: 0;
  // }

  // .fa-arrow-up-add-active, .fa-arrow-down-add-active {
  //   opacity: 1;
  // }
}
