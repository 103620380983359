@import "sortable-list";
@import "list-group";

li.faded {
  height: 10px !important;
  &:after {
    content: " ";
    height: 1px;
    background: $lightgrey;
    width: 100%;
    display: block;
    margin-top: 5px;
  }
}
