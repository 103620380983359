$pagColor: transparent;
$btnDarkenGreen: darken($btnGreen, 5%);
.tkg-pagination {
  font-size: 12px;
  margin-left: 10px;
  margin-right: 10px;



  .glyphicon, .fa {
    margin: 0;
    font-size: 12px;
  }
}

.pag-start {
   .tkg-pagination {
    margin-left: 0;
  }
}

.pagination {
  margin: 0 10px;
  & > li {
    cursor: pointer !important;
    & > * {
      cursor: pointer !important;
    }
  }
  & > li > a {
    border-color: darken($grey, 5%) !important;
    padding: 2px 5px;
    padding-bottom: 0px;
    color: black !important;
    background-color: $pagColor;
    &:not(.pag) {
      opacity: 0.6;
    }
    &:hover, &:focus {
      background-color: $lightgrey;
    }
    & > .glyphicon {
      margin: 0;
      top: 0;
      font-size: 10px;
    }
  }
  & > .active > a {
    opacity: 1 !important;
    background-color: $grey;

    &:hover, &:focus {
      background-color: $grey;
    }
  }

  & > .disabled > a {
    background-color: $lightgrey;
    &:hover, &:focus {
      background-color: $lightgrey;
    }
  }
}

.va {
  .tkg-pagination {
    .glyphicon {
      margin-top: -3px;
    }
    .icon-wrapper {
      margin-top: -3px;
      display: inline-block;
    }
  }
}

// Green

// .pagination {
//   margin: 0 10px;
//   & > li > a {
//     border-color: white !important;
//     padding: 2px 5px;
//     color: white;
//     background-color: lighten($btnDarkenGreen, 5%);
//     &:not(.pag) {
//       opacity: 0.6;
//     }
//     &:hover, &:focus {
//       color: white;
//       background-color: lighten($btnDarkenGreen, 8%);
//     }
//     & > .glyphicon {
//       margin: 0;
//       font-size: 10px;
//     }
//   }
//   & > .active > a {
//     opacity: 1 !important;
//     background-color: $btnDarkenGreen;
//     border-color: $btnDarkenGreen;
//     color: white;
//   }

//   & > .disabled > a {
//     cursor: default;
//     color: white;
//     background-color: lighten($btnDarkenGreen, 5%);
//     &:focus {
//       background-color: lighten($btnDarkenGreen, 5%);
//     }
//     &:hover {
//       color: white;
//       background-color: lighten($btnDarkenGreen, 8%);
//     }
//   }
// }

// Default

// .pagination {
//   margin: 0 10px;
//   & > li > a {
//     padding: 2px 5px;
//     & > .glyphicon {
//       margin: 0;
//       font-size: 10px;
//     }
//   }
// }
