$scrollableHeaderHeight: 25px;

.scrollable-div {
  position: relative;
  font-size: 12px;
  overflow: hidden;
}
.s-head {
  position: absolute;
  height: $scrollableHeaderHeight;
  white-space: nowrap;
  z-index: 2;
  background-color: white;
  cursor: pointer;
}
.s-body {
  margin-top: 25px;
  overflow: auto;
}
.with-msg {
  .s-body {
    margin-top: 50px;
  }
  .s-head.scroll-msg {
    display: block;
  }
}
.s-tr {
  white-space: nowrap;
  height: $scrollableHeaderHeight;
  &.hide:first-child {
    display: inherit !important;
    visibility: hidden;
  }
  &:nth-child(even) {
    background-color: $lightPoop;
    &.notify-bg:not(.selected):not(:hover) {
      background-color: $lightBlue;
    }
  }
  &:hover {
    background-color: $hoverGreen;
    opacity: 1;
  }
  &.selected {
    background-color: $selectGreen;
  }
  &:last-child {
    .s-td {
      border-bottom-width: 0;
    }
  }
}
.s-th, .s-td {
  display: inline-block;
  padding: 2px 5px !important;
  // overflow: hidden;
  // text-overflow: ellipsis;
  line-height: 25px;
  height: $scrollableHeaderHeight;
  border-style: solid;
  border-width: 0 1px 1px 0;
  border-color: $table-border-color;
  vertical-align: middle;
  &:last-child {
    border-right-width: 0;
  }
  &.inner-padding {
    padding: 0 !important;
    & > *:first-child > *:first-child {
      padding: 2px 5px !important;
    }
  }
}

.s-body > .s-tr:first-child {
  .s-td {
    border-top-width: 0;
  }
}
.s-th {
  font-weight: bold;
  border-top: 0;
  border-bottom-width: 1px;
  position: relative;
  i:not(.fa-cloud-upload) {
    position: absolute;
    right: 0;
    margin: 5px;
    margin-right: 0;
    padding: 0 2px;
    z-index: 2;
  }
  &.dragging-element {
    position: fixed;
    opacity: 0.7;
    border: 2px dotted darken($table-border-color, 10%);
    font-size: 12px;
    cursor: move;
    z-index: 20;
    @include no-select;
    @include pretty-textoverflow;
  }
  .arrow-spacing {
    .fa-arrow-up, .fa-arrow-down {
      margin-right: 18px;
    }
  }
}
.s-th-inner, .s-td-inner {
  display: inline-block;
  width: 100%;
  height: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  // position: relative;
}

// table msg
.scroll-msg {
  width: 100%;
  margin-top: 25px;
  display: none;
  text-align: center;
  cursor: default;
  & > .s-td {
    border-width: 0 0 1px 0;
    width: 100%;
  }
}

.modal {
  .scrollable-div {
    background: white;
    color: black;
  }
}

@mixin blink-background ($colorStart: $tabble-border-color, $colorStop: darken($table-border-color, 10%)) {
  from {background-color: $colorStart;}
  to {background-color: $colorStop;}
}
@keyframes blinkBackgroundLeft {
  @include blink-background($table-border-color, darken($table-border-color, 30%));
}
@-webkit-keyframes blinkBackgroundLeft {
  @include blink-background($table-border-color, darken($table-border-color, 30%));
}

#col-swapper {
  @extend #col-resizer;
  background-color: $lightgrey;
  &.on {
    -webkit-animation: blinkBackgroundLeft 1s infinite;
    animation: blinkBackgroundLeft 1s infinite;
  }
}
