.progress-bar-gold {
  background-color: $poop;
}
.progress {
  margin-bottom: 0;
}
.progress-bar {
  text-align: right;
  font-weight: bold;
}
.progressbar-value {
  color: $black;
}
