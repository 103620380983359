.dropdown-menu {
  font-size: 12px;
  min-width: inherit;
  // .fa-times {
  //   font-size: 16px !important;
  //   margin-top: 2px;
  // }
  &[uib-typeahead-popup] {
    min-width: fit-content;
  }
}
.source-list-dropdown.btn.btn-default {
  min-width: 100px !important;
  padding-right: 25px !important;
  & > .fa-caret-down {
    position: absolute;
    right: 10px;
    margin-right: 0;
  }
}

.source-list {
  max-height: 300px;
  overflow: auto;
  width: auto !important;
}

.stored-query-dropdown {
  li {
    min-height: 25px;
  }
  span.pull-left {
    line-height: 25px;
    height: 25px;
  }
}

.account-filter {
  display: flex !important;
  .fa-cloud-upload {
    color: black !important;
  }
  input[type="text"] {
    width: 95px !important;
    height: 19px !important;
    padding-right: 21px !important;
    border-right: none;
  }
  textarea {
    resize: both;
  }
  & > .input-group-addon {
    padding-right: 5px;
    padding-left: 5px;
    height: 19px !important;
    position: initial;
    display: flex;
    align-items: center;
    i {
      transition: opacity 0.5s ease-in-out;
    }
    &.addon-inline {
      background-color: $hoverGreen;
      border: none;
      padding: 0;
      padding-right: 3px;
      position: absolute;
      right: 28px;
      z-index: 3;
      height: 16px !important;
      i.fa-pencil-square-o {
        opacity: 0.6;
        margin-top: 4px;
      }
    }
    // &.left + input[type="text"] {
    //   padding-left: 5px !important;
    // }
  }
}
.account-tooltip {
  margin-left: 50px !important;
}

// .modal-content {
//   .account-filter {
//     input {
//       background-color: white !important;
//       width: 100% !important;
//       margin: 0 !important;
//     }
//     button {
//       padding-top: 1px !important;
//     }
//   }
// }

.custom-popup-wrapper .dropdown-menu {
  // position: static;
  display: block;
  float: none;
}

.checkbox-list > button {
  width: 100%;
}
