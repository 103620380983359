.stat-filter {
  .find-wrapper > * {
    min-width: 1340px;
  }
  .private-find-wrapper > * {
    min-width: 1500px;
  }
  .additional-wrapper > * {
    min-width: 1165px;
  }
}

// .stat-removeable-popover {
//   min-width: 450px !important;
//   .group-column {
//     width: 28%;
//   }
//   .field-column {
//     width: 72%;
//   }
// }
