$firstCol: 168px;
.dashboard-panel {
  .first-col {
    width: $firstCol;
    min-width: $firstCol;
    max-width: $firstCol;
  }
  tr {
    th:first-child, td:first-child {
      width: $firstCol;
      min-width: $firstCol;
      max-width: $firstCol;

      & > div {
        width: $firstCol - 10;
      }
    }
  }
  .separator-row {
    & > * {
      background: $lightPoop;
    }
  }
  table {
    // table-layout: fixed;
  }
}
#processing-overview-table {
  tr {
    td:not(:first-child) {
    // td:not(:last-child) {
      min-width: 80px;
    }
    // td:first-child {
    // }
  }
  tbody table {
    @extend .vert-border-none;
    // table-layout: fixed;
  }
}
