.alert {
  margin-bottom: 0;
}
.alert-success {
  padding: 5px;
}

.body-502 {
  width: 90%;
  text-align: center;
  margin: auto;
  margin-top: 10px;
}

.modal {
  .has-error {
    .form-control {
      border-color: $brightRed;
      border-width: 2px;
    }
  }
}

button.has-error {
  border-color: $brightRed !important;
}
