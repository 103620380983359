$scrollableHeaderHeight: 25px;

body.dragging {
  cursor: move !important;
}

.scrollable-table {
  border: none;
  & > thead, & > tbody {
    position: absolute;
  }
  & > thead {
    height: $scrollableHeaderHeight;
    overflow-x: hidden;
    overflow-y: hidden;
    z-index: 2;
    border-bottom: none;
    tr {
      display: block;
    }
    tr:first-child {
      position: absolute;
    }
  }
  & > tbody {
    overflow: auto;
    border-top: none;
    margin-top: $scrollableHeaderHeight - 1;
  }
  tr {
    height: $scrollable-cell-height + 2px * 2 + 1px;
    // position: relative;
  }
  td, th {
    padding: 2px 5px !important;
    line-height: 1.9 !important;
    @include pretty-textoverflow;
    &.monospace {
      line-height: 21px !important;
    }
    & > div:first-child {
      @include pretty-textoverflow;
      height: $scrollable-cell-height;
      float: left;
      display: $ib;
      // position: relative;
      // z-index: 2;
    }
  }

  th {
    cursor: pointer;
    @include no-select;
    position: relative;
  }

  .sort-margin-right + .fa {
    margin-right: 15px;
  }
}

body.resizing.ew {
  th {
    cursor: ew-resize !important;
  }
}

@import 'resizable-column';
@import 'draggable-column';
@import 'removeable-column';
@import 'table-msg';
