.capacity-screen {
  .td-label.text-center {
    text-align: center;
  }

  .full-case-info-table {
    tr {
      td:not(:first-child) {
        &:before {
          content: none;
          padding-left: 0;
        }
      }
    }
  }
  .table-border-left {
    border-left: $table-border !important;
  }
  .rule-dropdown {
    min-width: 160px;
  }
}
