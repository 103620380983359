.tooltip-inner {
  background: $lightPoop;
  border: 1px solid $poop;
  border-radius: $borderRadius;
  box-shadow: 0 0 2px 1px $poop;
  opacity: 1;
  color: $black;

  .pink-bg {
    background-color: darken($lightPink, 15%);
  }
}

.tooltip.top .tooltip-arrow {
  border-top-color: $poop;
}

.tooltip.bottom .tooltip-arrow {
  border-bottom-color: $poop;
}

.tooltip.left .tooltip-arrow {
  border-left-color: $poop;
}

.tooltip.right .tooltip-arrow {
  border-right-color: $poop;
}
.tooltip.top-left .tooltop-arrow {
  border-top-color: $poop;
}

.black-tooltip {
  .tooltip-inner {
    background: rgba(0,0,0,0.6);
    border-color: rgba(0,0,0,0.7);
    box-shadow: 0 0 2px 1px $black;
  }

  .tooltip-arrow {
    border-bottom-color: rgba(0,0,0,0.6) !important;
  }
}

.no-max-width {
  .tooltip-inner {
    max-width: inherit;
  }
}

.initial-width {
  .tooltip-inner {
    max-width: initial;
  }
}

.tooltip-50 {
  .tooltip-inner {
    max-width: 500px;
  }
}

.tooltip-75 {
  .tooltip-inner {
    max-width: 750px;
  }
}

.tooltip-right {
  right: 3px !important;
  left: inherit !important;
}

.tooltip-flow {
  max-height: 500px;
  overflow: auto;
}
.tooltip-flow-wrapper {
  .tooltip-inner {
    padding: 0;
    .pretty-text {
      overflow: initial;
    }
  }
}

@import "popover";
