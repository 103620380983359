#selected-case-info-wrapper {
  margin-top: 10px;
  width: 100%;
  overflow: auto;
  max-height: 50vh;

  .logo {
    float: right;
    margin-top: 10px;
    margin-right: 10px;
    // width: 50px;
    // height: 50px;
    // // margin-left: 37vw;
    // position: absolute;
    // display: inline-block;
    // top: 10px;
    // right: 10px;
  }
  .footer {
    min-width: 1350px;
  }
}

.selected-case-info {
  background: white;
  color: $black;
  th, td {
    border: 1px solid black !important;
    // font-size: 12px;
  }
}

#selected-case-info {
  @extend .selected-case-info;
  border: 1px solid black !important;
  min-width: 1350px;
}


#selected-case-info {
  .share-table-details {
    // padding-top: 8px;
    // padding-bottom: 8px;
    display: flex;
    table {
      min-width: 530px;
      float: left;
      width: initial !important;
      margin-left: 25px;
      margin-top: 0 !important;
    }
  }
}

.modal-grey {
  table.table-bordered {
    border-color: #c1c1c1 !important;
    td, th {
      border-color: #c1c1c1 !important;
    }
  }
}

#cloned-selected-wrapper {
  position: fixed;
  top: 100%;
  left: 0;
  .logo {
    float: right;
    margin-top: 10px;
    margin-right: 10px;
  }
  .footer {
    min-width: 1350px;
  }
  td {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
}
