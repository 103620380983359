#nms-stat {
  [uib-dropdown] {
    width: initial !important;
    label {
      float: none !important;
      color: black;
      a {
        color: $linkColor;
      }
    }
    ul:not([ng-show]) {
      margin: 0;
    }
    .fa-caret-down {
      margin-right: 0;
      margin-left: 5px;
      float: none;
    }
  }

  [uib-dropdown-menu] {
    padding: 10px;

    .list-group-item {
      &.active {
        & > * {
          color: white;
        }
      }
      & > * {
        padding-left: 5px;
      }
    }
  }

  #market-center {
    [uib-dropdown-menu] {
      width: 300px;
    }
  }

  .order-type {
    [uib-dropdown-menu] {
      width: 200px;
    }
  }

  .order-size {
    [uib-dropdown-menu] {
      width: 200px;
    }
  }

  #symbol-group {
    [uib-dropdown-menu] {
      width: 150px;
    }
    input.md {
      max-width: 75px !important;
      width: 100% !important;
    }
  }

  #field-mgmt {
    [uib-dropdown-menu] {
      width: 200px;
    }
  }

  #group {
    [uib-dropdown-menu] {
      width: 200px;
    }
  }
}

.report-navigation {
  display: inline-block;
  // position: absolute;
  // left: 50px;
  .tkg-pagination {
    position: absolute;
    left: 50px;
    top: 9px;
  }
}

.order-modal {
  .modal-dialog {
    width: 900px;
  }

  .table-bordered {
    border-right: $table-border !important;
    border-left: $table-border !important;
    display: block;
    over: hidden;
  }

  tr{
    div {
      height: 15px;
    }
    td {
      vertical-align: middle !important;
    }
    td:first-child {
      width: 170px;
      min-width: 170px
    }
    td:nth-child(2) {
      width: 100%;
      & > * {
        max-width: 250px;
        // border: none;
        @extend .pretty-text;
      }

    }
  }

}
