.relative {
  position: relative !important;
}
.absolute {
  position: absolute !important;
}
.p-inital {
  position: initial !important;
}
.show {
  display : inherit !important;
}
.pointer {
  cursor: pointer !important;
}

.pointer-normal {
  cursor: default !important;
}

.pull-none {
  float: none !important;
}

.padding-2 {
  padding: 2px !important;
}

.padding-5 {
  padding: 5px !important;
}

.padding-10 {
  padding: 10px !important;
}

.padding-cell {
  padding: $cellPadding;
}


.padding-top {
  padding-top: 10px !important;
}

.padding-top-xs {
  padding-top: 5px !important;
}

.padding-bottom, .padding-bot {
  padding-bottom: 10px !important;
}

.padding-right {
  padding-right: 10px;
}

.padding-right-i {
  padding-right: 10px !important;
}

.padding-left-xs {
  padding-left: 5px !important;
}

.padding-left {
  padding-left: 10px;
}

.padding-left-lg {
  padding-left: 15px !important;
}

.padding-v, .vert-padding {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.padding-h, .h-padding {
  padding-right: 10px !important;
  padding-left: 10px !important;
}
.padding-h-s {
  padding-right: 5px;
  padding-left: 5px;
}

.padding-left-none {
  padding-left: 0 !important;
}
.padding-right-none {
  padding-right: 0 !important;
}
.padding-top-none {
  padding-top: 0 !important;
}
.padding-bottom-none {
  padding-bottom: 0 !important;
}

.padding-none {
  padding: 0 !important;
}

.emphasize {
  font-weight: 500;
}

.strong {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.full-width, .w-full {
  width: 100% !important;
  clear: both;
}

.block {
  display: block !important;
  @extend .full-width;
}

.clear-right {
  clear: right;
}

.clear-left {
  clear: left;
}

.clear {
  clear: both;
}
.group:after {
  visibility: hidden;
  display: block;
  content: "";
  clear: both;
  height: 0;
}

.inherit-height {
  height: inherit;
}

.max-height {
  height: 100%;
}

.auto-height {
  height: auto !important;
}

.max-height-200 {
  max-height: 200px;
}

.max-height-500, .m-h-500 {
  max-height: 500px;
}

.m-h-450 {
  max-height: 450px;
}

.right {
  float: right !important;
}

.left {
  float: left !important;
}



.not-ready {
  border: 3px solid $pink !important;
}

// .error-msg {
//   color: $state-danger-text !important;
//   padding: 10px;
//   border: 1px solid $state-danger-text !important;
//   display: block;
//   width: 100%;
//   margin-bottom: 15px;
//   font-weight: bold;
// }

.error-msg {
  color: $state-danger-text !important;
  padding: 2px 5px;
  border: 1px solid $state-danger-text !important;
  // display: block;
  // width: 100%;
  // margin-bottom: 15px;
  font-weight: bold;
  &.bright-red-text {
    color: $brightRed !important;
    border: 1px solid $brightRed !important;
  }
}

.exception {
  color: white;
  background-color: $hoverGreen;
  padding: 0 10px;
  color: black;
  font-weight: bold;
  border: 2px solid $hoverGreen;
}

.priority-text {
  background: inherit;
  z-index: 2;
}

.small {
  font-size: 85%;
}

.large {
  font-size: 115% !important;
}

$leftStart: 0;
$leftEnd: 300;
@while $leftStart < $leftEnd {
  .left-#{$leftStart} {
    margin-left: -$leftStart + px;
  }
  $leftStart: $leftStart + 20;
}

.left-spacing-none {
  margin-left: 0 !important;
}

.left-spacing {
  margin-left: 10px !important;
}

.left-spacing-xs {
   margin-left: 5px !important;
}

.left-spacing-lg {
  margin-left: 15px !important;
}

.left-spacing-xlg {
  margin-left: 20px !important;
}

.right-spacing {
  margin-right: 10px !important;
}
.right-spacing-xs {
  margin-right: 5px !important;
}

.right-spacing-lg {
  margin-right: 15px !important;
}

.right-spacing-xlg {
  margin-right: 20px !important;
}

.top-spacing {
  margin-top: 10px !important;
}
.top-spacing-xs {
  margin-top: 5px !important;
}
.top-spacing-xxs {
  margin-top: 2px !important;
}
.top-spacing-xs-r {
  margin-top: -5px !important;
}
.top-spacing-xxs-r {
  margin-top: -2px !important;
}
.top-spacing-lg {
  margin-top: 15px !important;
}
.top-spacing-xlg {
  margin-top: 20px !important;
}

.bot-spacing {
  margin-bottom: 10px !important;
}
.bot-spacing-xs {
  margin-bottom: 5px !important;
}
.bot-spacing-lg {
  margin-bottom:  15px !important;
}
.bot-spacing-xlg {
  margin-bottom:  20px !important;
}

.vert-spacing {
  margin-top: 10px;
  margin-bottom: 10px;
}

.vert-spacing-xs {
  margin-top: 5px;
  margin-bottom: 5px;
}

.vert-spacing-none {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.h-spacing {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.h-spacing-xs {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.h-margin-none {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.margin-top-none {
  margin-top: 0 !important;
}

.margin-bot-none {
  margin-bottom: 0 !important;
}

.margin-right-none {
  margin-right: 0 !important;
}

.margin-left-none {
  margin-left: 0 !important;
}

.margin-h-none {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.margin-none {
  margin: 0 !important;
}

.border-none {
  border: none !important;
}

.overflow {
  overflow: auto;
}

.overflow-x {
  overflow-x: auto;
  overflow-y: hidden;
}

.overflow-y {
  overflow-y: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.font-12 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-16 {
  font-size: 16px !important;
}

.lh-21, .lh21 {
  line-height: 21px;
}

.lh-22, .lh22 {
  line-height: 22px;
}

.lh-18, .lh18 {
  line-height: 18px;
}

.h-18 {
  @extend .lh-18;
  height: 18px;
}
.h-22 {
  @extend .lh-22;
  height: 22px;
}

.lh-14, .lh14 {
  line-height: 14px;
  vertical-align: middle;
}

.lh-22, .lh22, .lh-18, .lh18 {
  vertical-align: middle;
  span {
    vertical-align: middle;
    margin: 0 5px;
    margin-left: 0;
  }
}

.fade-effect {
  opacity: 1;
}

.disabled-effect {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.disabled {
  text-decoration: none;
  cursor: default;
}

a.disabled {
  color: $black;
  &:hover {
    text-decoration: none;
  }
}

.ultra-light-effect {
  opacity: 0.2;
}

.lighter-text {
  opacity: 0.5;
  transition: opacity 1s ease-in-out;
}

.dim-text {
  color: lighten($black, 20%);
  opacity: 0.8;
}

.light-yellow-bg {
  background-color: $lighterPoop;
}

.yellow-bg {
  background-color: $lightPoop;
}

.green-bg {
  background-color: $green;
}

.light-green-bg {
  background-color: $lighterHoverGreen;
}

.light-green-f-bg {
  background-color: $lighterHoverGreen !important;
}

.bright-green-bg {
  background-color: $brightGreen !important;
}

.white-bg {
  background: white;
}

.light-grey-bg {
  background-color: $lightgrey;
}

.darker-blue-bg {
  background-color: $darkerBlue;
}

.darker-blue-f-bg {
  background-color: $darkerBlue !important;
}

.dark-red-bg {
  background-color: $darkRed !important;
}

.blue-bg {
  background-color: $lightBlue;
}

.blue-f-bg {
  background-color: $lightBlue !important;
}

.pink-bg {
  background-color: $lightPink;
}

.notify-bg {
  background-color: $lightBlue;
  transition: background-color 1s ease-in-out;
}

.red-bg {
  background-color: $lightPink !important;
}

.action-red-bg {
  background-color: lighten($darkRed, 15%) !important;
  color: white;
  &:hover {
    color: inherit;
  }
}

.error-bg {
  background-color: $brand-danger !important;
}

.glyphicon {
  cursor: pointer;
}

.invisible {
  visibility: hidden;
}

.dashed-bot {
  border-bottom: 1px dashed $black;
}

.th-text-center {
  th {
    text-align: center;
  }
}

.tkg-nav {
  padding: 0;
  font-size: 12px;
  li {
    display: inline-block;
    padding-left: 2px;
    &:not(:last-child):after {
      content: '|';
      padding: 0 2px;
      // padding-right: 3px;
      // color: $lightgrey;
    }
  }
  a {
    cursor: pointer;
  }
}

// uib-dropdown-menu
.dropdown-filter {
  top: 27px;
  left: 4px;
  width: 205px;
  padding: 10px;
  li {
    @include dropdown-li;
    span:first-child {
      text-align: right;
      padding-right: 10px;
      width: 95px;
      float: left;
      line-height: 21px;
    }
    input[type="text"] {
      width: 85px;
      padding-right: 20px !important;
    }
    .glyphicon-remove {
      position: absolute;
      right: 0;
      width: 12px;
      height: 12px;
      line-height: 12px;
      margin-top: 3px;
      margin-right: 6px;
      cursor: pointer;
      display: inline-block;
      color: darken($lightgrey, 20%);
    }
  }

  &.xs {
    width: 150px;
    min-width: 0;
  }

  &.sm {
    width: 205px;
    li {
      span:first-child {
        width: 95px;
      }
      input[type="text"] {
        width: 85px;
      }
    }
  }
  &.md {
    width: 270px;
    li {
      span:first-child {
        width: 145px;
      }
      input[type="text"] {
        width: 100px;
      }
    }
  }

  input[type="checkbox"] + span {
    padding-left: 5px;
    color: black;
  }
}

.ib {
  display: inline-block
}

.ibf {
  display: inline-block;
  width: 100%;
}

.two-columns {
  li {
    display: inline-block;
    float: left;
    width: 50%;
  }
}

.half-width, .w-50 {
  width: 50%;
  display: inline-block;
  &:first-child {
    float: left;
  }
}

.pretty-text {
  @include pretty-textoverflow;
  display: inline-block;
}

.w-100 {
  width: 100px !important;
}

.w-150 {
  width: 150px !important;
}
.w-200 {
  width: 200px !important;
}
.w-225 {
  width: 225px !important;
}
.w-250 {
  width: 250px !important;
}
.m-w-100 {
  width: 100px !important;
}

.m-w-150 {
  max-width: 150px !important;
}
.m-w-200 {
  max-width: 200px !important;
}
.m-w-225 {
  max-width: 225px !important;
}
.m-w-250 {
  max-width: 250px !important;
}


@media screen and (max-width: $hideXS) {
  .tkg-hide-xs {
    display: none !important;
  }
}

@media screen and (max-width: $hideMD) {
  .tkg-hide-md {
    display: none !important;
  }
}

@media screen and (max-width: $hideXL) {
  .tkg-hide-xl {
    display: none !important;
  }
}

.monospace {
  font-family: monospace;
  font-size: 1.1em;
}

.text-cusip {
  text-align: right;
  @extend .monospace;
}

.flip {
  transform: rotate(180deg);
}

.underline {
  text-decoration: underline;
}

.vertical-align, .va {
  * {
    vertical-align: middle;
  }
  input[type="checkbox"], input[type="radio"] {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 5px;
    margin-right: 5px;
  }
}

.va-top {
  vertical-align: top;
}

.vertical-align-none, .va-none {
  vertical-align: initial;
}

.h-10 {
  height: 10px !important;
}

.h-300 {
  height: 300px;
  overflow-y: auto;
}

.max-h-300 {
  max-height: 300px;
  overflow-y: auto;
}

.nowrap {
  white-space: nowrap;
}

[hidden], [hidden="true"] {
  display: none !important;
}

[invisible], [invisible="true"] {
  visibility: hidden !important;
}
