.btn {
  .fa.fa-chevron-down {
    margin: 0;
    margin-left: 5px;
    font-size: 12px;
    float: none;
  }
  .fa.fa-caret-down {
    margin-left: 5px;
  }
}
