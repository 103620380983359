h1, h2, h3, h4, h5 {
  margin: 0;
}

a {
  cursor: pointer;
  &.focus {
    text-decoration: underline;
    color: lighten($anchorColor, 30%);
  }
  &.white-text {
    &:hover, &:focus {
      color: $lightgrey;
    }
    &:active {
      color: $grey;
    }
  }
  &.underline {
    text-decoration: underline;
  }
}

ul {
  list-style: none;
  padding: 0;
}

abbr {
  cursor: inherit !important;
}

label {
  font-weight: normal;
}

.form-inline .form-group {
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
}

.golden-border {
  @include golden-border;
}

.golden-border-1 {
  @include golden-border(1px);
}

@each $side in left, bottom, right, top {
  .golden-border-#{$side} {
    @include single-golden-border($side);
  }
  .thick-border-#{$side} {
    @include single-golden-border($side, $darkerGrey, 3px);
  }
}

.green-border {
  @include green-border;
}

hr {
  margin-top: 10px;
  margin-bottom: 10px;
}

:focus, :active:focus {
  outline: none !important;
}
