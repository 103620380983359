.nms-filter {
  // @extend .pretty-inputs;
  .filter-wrapper {
    overflow-x: auto;
    overflow-y: hidden;
    & > * {
      padding-right: 0;
    }
  }
  .filter-category {
    position: relative;
    height: 19px;
    font-weight: bold;
    @extend .block;
    @extend .bot-spacing;
    &.xs {
      @extend .bot-spacing-xs;
    }
    // & + * {
    //   @extend .top-spacing;
    // }
    &.ib {
      display: inline-block !important;
      @extend .bot-spacing-xs;
    }
    .fa-refresh {
      float: right;
      margin-right: 2px;
      margin-top: 3px;
    }
    .fa-magnet {
      float: right;
      margin-top: 2px;
    }
    input {
      font-weight: normal;
    }
  }

  .category, .stock-list-wrapper, .market-center-wrapper {
    margin-top: 10px;
    float: left;
    margin-right: 15px;
    height: $categoryHeight;
    &.reduced {
      margin-top: -19px;
    }
    input[type="radio"] {
      margin-left: 5px;
    }
  }

  div > .category:last-child {
    margin-right: 0;
  }

  .stock-list-wrapper, .market-center-wrapper {
    margin-top: 0;
  }

  .summary-category {
    @extend .category;
    height: $categoryHeight/2;
  }

  #filter-name {
    width: 100px;
  }

  #defined-list {
    width: 200px;
  }

  .date-filter {
    width: 95px;
  }

  .private-date-filter {
    width: 145px;
    .fa-chevron-down {
      margin-top: -1px !important;
    }
  }

  .private-date-filter-v2 {
    width: 250px;
    .fa-chevron-down {
      margin-top: -1px !important;
    }
    .col-xs-6 {
      padding: 0;
    }
  }

  .market-center {
    width: $marketCenterWidth;
  }

  .short-market-center {
    width: 145px;
  }

  // .market-center-wrapper {
  //   width: $marketCenterWidth * 2 + 50px;
  // }

  .stock-list {
    width: 330px;
  }

  .short-stock-list {
    width: 285px;
  }

  .order-type {
    width: 160px;
  }

  .private-order-type {
    width: 180px;
  }


  .order-size {
    width: 170px;
  }

  .sort-field {
    width: 215px;
  }

  .group {
    width: 200px;
  }

  .symbol-group {
    width: 140px;
  }

  .field-selection {
    width: 300px;
  }

  .additional-setting {
    width: 220px;
  }

  .equality-filter {
    // width: 320px;
    tr {
      td:first-child {
        text-align: right;
      }
      td:last-child {
        padding-right: 0 !important;
      }
    }
    &.execution_speed {
      width: 395px;
    }
    table {
      padding: 0 !important;
    }
  }

  .alert {
    font-size: 12px;
    margin: 0;
    padding: 0px 10px !important;
    display: inline-block;
    border: none;
    .fa-times {
      float: right;
      margin: 2px 0 0 10px;
    }
    &.button-alert {
      padding: 2px 10px !important;
    }
  }

  .exchanger {
    display: inline-block;
    float: left;
    margin-left: -6px;
    padding-right: 8px;
    i {
      cursor: default;
      display: block;
      &:first-child {
        margin-top: $categoryHeight / 2 - 20;
      }
    }
  }

}

.sortable {
  .list-group-item {
    border-top-color: white;
    border-bottom-color: white;

    &.sort-dragging {
      border-radius: 0;
    }
  }
  .list-group-item {
    cursor: move;
  }
}

.user-defined-tooltip {
  .tooltip-inner {
    display: inline-block;
    padding: 5px;

    .list-group {
      margin: 0;
    }
    ul + ul.list-group {
      margin-top: 10px;
    }
  }
}

.summary-detail {
  font-size: 12px;
  padding: 10px;
  display: inline-block;
  width: 100%;
  .fa {
    cursor: default;
  }
  p {
    line-height: 20px;
    margin: 0;
  }
  strong {
    width: 130px;
    text-align: right;
    float: left;
    font-weight: 500;
  }
  h5 {
    font-size: 14px;
  }
  & > .overview-tab {
    margin-bottom: 5px;
    &:nth-child(odd) {
      clear: left;
      padding-left: 0;
    }
    &:nth-child(even) {
      clear: right;
      padding-right: 0;
    }
  }
}

.summary-detail-expand {
  strong + span {
    display: block;
    padding-left: 120px;
    text-align: left;
  }
}


.summary-detail-tooltip {
  width: 500px;
  hr {
    border-color: $black;
  }
  // .tab-name {
  //   text-align: right;
  //   h5 {
  //     width: 130px;
  //   }
  // }
}


.setting-dropdown {
  min-width: 200px;
}

[uib-typeahead-popup] {
  .label {
    float: right;
    margin-top: 3px;
    margin-left: 10px;
  }
}

.label-purple {
  @extend .label-danger;
  background: #a47bb5;
}

.label-quality, .label-trans {
  @extend .label-success;
}

.label-shares, .label-route {
  @extend .label-default;
}

.label-orders, .label-order {
  @extend .label-warning;
}

.label-type {
  @extend .label-warning;
}

.label-analysis {
  @extend .label-danger;
  background: #a7947a;
}

.label-user {
  @extend .label-danger;
  background: #e244b9;
}

.label-speed, .label-nbbo {
  @extend .label-danger;
}

.label-TKG, .label-605 {
  @extend .label-primary;
}

.label-mc, .label-606, .label-606a, .label-overall {
  @extend .label-info;
  background-color: darken($defaultBlue, 10%);
}

.label-mm {
  @extend .label-info;
}

.label-spec, .label-TSPilot, .label-group {
  @extend .label-info;
  background-color: darken($defaultBlue, 15%);
}

.label-unknown {
  @extend .label-info;
  background-color: darken($defaultBlue, 13%);
}

.label-symbol {
  @extend .label-warning;
}

.label-listed {
  @extend .label-success;
}

.label-traded {
  @extend .label-info;
}

.label-index {
  @extend .label-default;
}

.label-custom  {
  @extend .label-primary;
}

.label-ats {
  @extend .label-primary;
  background: #337ab7 !important;
}

.label-exch {
  @extend .label-success;
}

.mc-field-saver {
  min-width: $marketCenterWidth !important;
  .fa {
    float: none !important;
  }
}

.stock-field-saver {
  @extend .mc-field-saver;
  min-width: 330px !important;
}

.max-width-600 .tooltip-inner {
  max-width: 600px;
}

.popover {
  .category {
    height: 390px;
  }
  .nms-filter {
    $max: 8;
    $wrapperHeight: $itemHeight * 8 + 10px;
    @for $i from  1 through $max {
      .compressed-#{$i}.list-group-wrapper {
        height: $wrapperHeight + (($max - $i) * $itemHeight) - 4px;
      }
      .expand-#{$i}.list-group-wrapper {
        height: $itemHeight * $i + 14px;
        @if $i != 8 {
          overflow: hidden;
        }
      }
    }
  }
}
