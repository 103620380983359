.input-group {
  .form-control:first-child:not(textarea) {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    padding-right: 30 !important;
    width: 100px !important;
  }
}
input + * + .input-group-addon, input + .input-group-addon {
  z-index: 3;
  display: flex;
  align-items: center;
  padding-right: 5px;
}
