.modal-more-filter {
  .dropdown-row {
    height: 30px;
  }
  .dropdown-menu, .dropdown-toggle {
    width: 122px !important;
  }

  .eq-table {
    & > tbody > tr > td:first-child {
      width: 135px;
    }
  }
  .col {
    padding: 0;
  }
  label {
    margin-bottom: 0;
    margin-top: 2px;
  }
  & > h5:not(:first-child) {
    margin-top: 10px;
  }
  .list-group-item {
    border: none;
  }
}

.modal-capacity-more {
  .modal-dialog {
    width: 240px;
  }
}

.modal-capacity-more-filter {
  .regex-filter {
    input {
      margin-right: 0;
    }
  }
  .regex-child:not(:first-child) {
    margin-top: 10px;
  }
}
