.d-flex {
  display: flex !important;
}

.d-flex-not-important {
  display: flex;
}

.ml-auto {
  margin-left: auto;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.align-items-center {
  align-items: center;
}

.align-self-start {
  align-self: flex-start;
}

.d-flex-center {
  display: flex !important;
  align-items: center;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}
